import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql, HeadProps } from 'gatsby'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { Layout, Masthead, H2, LargeP, Grid, PointsCard, Lifter, Glow, Dashboard, DataSprites, HeadTags } from '@components'
import { useWindowSize } from '@utilities'

interface PageProps {
  data: Queries.IndexPageQuery
}

const IndexPage = ({ data: { sanitySiteSettings, sanityPage } }: PageProps) => {
  if (!sanityPage || !sanitySiteSettings) return <></>
  const { sprites, mastheadVideo } = sanityPage
  const { announcementRibbon } = sanitySiteSettings
  const handWithPhoneImage = sprites?.find(sprite => sprite?.key === 'handWithPhoneImage')
  const mastheadVideoBg = sprites?.find(sprite => sprite?.key === 'mastheadVideoBg')

  const { width } = useWindowSize()

  const [t, setT] = useState(0)
  useScrollPosition(
    ({ currPos }) => setT(currPos.y), // effect callback
    [], // dependencies
    undefined, // position of element
    true, // use window instead of body.getBoundingClientRect
    10, // performance debounce
  )

  const pointCardOne =
    width >= 944
      ? [
          { t: 0, v: -500 },
          { t: 0.4, v: 0 },
        ]
      : [
          { t: 0, v: -300 },
          { t: 0.25, v: 0 },
        ]

  const pointCardTwo =
    width >= 944
      ? [
          { t: 0, v: -500 },
          { t: 0.4, v: 0 },
        ]
      : [
          { t: 0, v: -300 },
          { t: 0.2, v: 0 },
        ]

  const pointCardThree =
    width >= 944
      ? [
          { t: 0, v: -500 },
          { t: 0.4, v: 0 },
        ]
      : [
          { t: 0, v: -300 },
          { t: 0.15, v: 0 },
        ]

  const glow1Timeline = [
    { t: 0, v: 0 },
    { t: 0.8, v: 0 },
    { t: 0.87, v: 1 },
    { t: 0.95, v: 0 },
    { t: 1, v: 0 },
  ]

  const glow2Timeline = [
    { t: 0, v: 0 },
    { t: 0.55, v: 0 },
    { t: 0.6, v: 1 },
    { t: 0.7, v: 0 },
    { t: 1, v: 0 },
  ]

  return (
    <>
      <Layout flushTop>
        <Masthead {...{ mastheadVideo, handWithPhoneImage, mastheadVideoBg }} headerHasAnnouncement={!!announcementRibbon} />

        <Spacer />

        <StyledGrid>
          <Pair>
            <Lifter gridArea="loyaltyCopy" {...{ t }}>
              <H2>Loyalty leveled up</H2>
              <LargeP>Super-power customer engagement, driving more frequent, and more meaningful, interactions.</LargeP>
            </Lifter>
            <OffsetLeftPlaceholder>
              <PointsCard src={sprites?.find(sprite => sprite?.key === 'reward-1')?.image?.asset?.url || ''} {...{ t }} offset={0} timeline={pointCardOne} />
              <PointsCard src={sprites?.find(sprite => sprite?.key === 'reward-2')?.image?.asset?.url || ''} {...{ t }} offset={100} timeline={pointCardTwo} />
              <PointsCard src={sprites?.find(sprite => sprite?.key === 'reward-3')?.image?.asset?.url || ''} {...{ t }} offset={200} timeline={pointCardThree} />
            </OffsetLeftPlaceholder>
          </Pair>

          <Pair>
            <Lifter gridArea="rewardsCopy" {...{ t }}>
              <H2>Next-generation rewards engine</H2>
              <LargeP>Redefine outdated, one-size-fits-all benefits with fully customizable, easily redeemable rewards.</LargeP>
            </Lifter>
            <DataSprites {...{ t, sprites }} />
          </Pair>

          <Pair>
            <Lifter gridArea="dataCopy" center {...{ t }}>
              <H2>Your data science toolbox</H2>
              <LargeP>An intuitive dashboard for in-depth reporting to gain more robust insights into your customers.</LargeP>
            </Lifter>
            <Dashboard {...{ t, sprites }} />
          </Pair>
        </StyledGrid>
      </Layout>
      <GlowOne {...{ t }} timeline={glow1Timeline} />
      <GlowTwo {...{ t }} timeline={glow2Timeline} />
    </>
  )
}

const Spacer = styled.div`
  height: 100px;
  @media only screen and (min-width: 944px) {
    height: 100px;
  }
`

const StyledGrid = styled(props => <Grid {...props} />)`
  @media only screen and (min-width: 944px) {
    grid-template-areas:
      'loyaltyImage loyaltyImage loyaltyImage loyaltyImage loyaltyImage loyaltyImage loyaltyImage loyaltyCopy loyaltyCopy loyaltyCopy loyaltyCopy loyaltyCopy'
      'rewardsCopy rewardsCopy rewardsCopy rewardsCopy rewardsCopy . rewardsImage rewardsImage rewardsImage rewardsImage rewardsImage rewardsImage'
      '. . dataCopy dataCopy dataCopy dataCopy dataCopy dataCopy dataCopy dataCopy . .'
      'dataImage dataImage dataImage dataImage dataImage dataImage dataImage dataImage dataImage dataImage dataImage dataImage';
  }
`

const Pair = styled.div`
  display: contents;
`

const OffsetLeftPlaceholder = styled.div`
  grid-area: loyaltyImage;
  grid-column: 1 / span 6;
  position: relative;
  height: calc(50vh);
  @media only screen and (min-width: 944px) {
    grid-column: 1 / span 6;
    height: 700px;
  }
`

const ImageWrapper = styled.div<{ gridArea?: string; column?: string }>`
  position: relative;
  overflow: hidden;
  grid-column: 1 / span 6;
  @media only screen and (min-width: 944px) {
    grid-area: ${props => props.gridArea || ''};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
`

const GlowOne = styled(props => <Glow {...props} />)`
  width: 100vw;
  height: 100vw;
  right: 0;
  bottom: 25vw;
  background: radial-gradient(circle at 70% 50%, rgba(207, 78, 238, 0.32) 0%, rgba(249, 249, 251, 0.32) 45%, rgba(249, 249, 251, 0) 100%);
  @media only screen and (min-width: 944px) {
    width: 100vw;
    height: 100vh;
    right: 0;
    bottom: 0;
  }
`

const GlowTwo = styled(props => <Glow {...props} />)`
  width: 80vw;
  height: 80vw;
  left: 0;
  top: 0;
  background: radial-gradient(circle at 20% 50%, rgba(207, 78, 238, 0.32) 0%, rgba(249, 249, 251, 0.32) 45%, rgba(249, 249, 251, 0) 100%);
  @media only screen and (min-width: 944px) {
    left: 0;
    width: 100vw;
    height: 100vh;
  }
`

export const Head = (props: HeadProps) => (
  <HeadTags {...props}>
    <link rel="canonical" href="https://usepower.com/" />
  </HeadTags>
)

export const query = graphql`
  query IndexPage {
    sanityPage(slug: { current: { eq: "index" } }) {
      mastheadVideo {
        asset {
          url
        }
      }
      sprites {
        key
        altText
        image {
          asset {
            url
          }
        }
      }
    }
    sanitySiteSettings {
      announcementRibbon
    }
  }
`

export default IndexPage
